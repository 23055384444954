<template>
  <ManageUsersGroupsCreateUser
          :UG="UG"
          :accessRoles="accessRoles"
          @save="save({edit: true})"
  />
</template>

<script>
  import ManageUsersGroupsCreateUser from "./ManageUsersGroupsEditUser/ManageUsersGroupsEditUser";
  import {UserGroups} from "../models/UserGroups";
  import {userGroupsMixin} from "../../../../../mixins/manageUsersMixins/userGroupsMixin";
  import {SYSTEM_ROLE_USER} from "../../../../../staticData/staticVariables";

  export default {
    name: "ManageUsersGroupsEdit",
    components: {
      ManageUsersGroupsCreateUser,
    },

    mixins: [userGroupsMixin],

    data() {
      return {
        UG: new UserGroups(),
        accessRoles: [],
      }
    },

    mounted() {
      this.initUserGroupEdit()

    },

    methods: {
      initUserGroupEdit() {
        this.$store.dispatch('fetchPermissionsGroups', `?filter[permissionGroupRole.role_id]=${SYSTEM_ROLE_USER.id}`).then(response => {
          this.UG.setPermissions(false)
          this.UG.setPermissions(this.getRespData(response))

          this.$store.dispatch('getManageUsersGroups', this.$route.params.id).then(responseItem => {
            let data = this.getRespData(responseItem)

            this.UG.setItem(data)
            console.log(111111111111111, data);
            this.UG.setPermissionsByUserData(data.permissions)
          }).catch(error => this.createErrorLog(error))
        }).catch(error => this.createErrorLog(error))
      }
    }

  }
</script>

<style scoped>

</style>
