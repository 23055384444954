<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['manageUsers_userGroup'])"></div>
      <CardLeftBlock
          :name="$t('manageUsers_userGroup.localization_value.value')"
          :value="UG.data.name"
          :backgroundImage="'subuser'"
          class="sub-user"
      >
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['manageUsers_backTo'])"></div>
                <router-link :to="$store.getters.GET_PATHS.mainSettingsManageUsersLink" class="detail-page__head-back">
                  <LinkBack
                      :value="$t('manageUsers_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['manageUsers_EditUserGroup'])"></div>
              {{$t('manageUsers_EditUserGroup.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col custom-col--33 custom-col--xs-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_name'])"></div>
                <DefaultInput
                    :label="$t('common_name.localization_value.value')"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': UG.validation.name}"
                    :error="UG.validation.name"
                    :errorTxt="$t(`${UG.validationTranslate.name}.localization_value.value`)"
                    v-model="UG.data.name"
                />
              </div>
            </div>

            <Permissions
                    :UG="UG"
                    :ManagePermissions="UG.data.ManagePermissions"
            />

          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.mainSettingsManageUsersLink"
                           class="site-link site-link--alt"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto">
              <MainButton
                  class="order-create__footer-btn-i"
                  :value="$t('common_edit.localization_value.value')"
                  @click.native="$emit('save')"
                  v-bind:class="{'disabled-btn' : $store.getters.getManageUsersBtn}"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>

    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import Permissions from "../../chunks/Permissions/Permissions";
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";



  export default {
    name: "ManageUsersGroupsEditUser",

    components: {
      DefaultInput,
      Permissions,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
    },

    mixins: [mixinDetictingMobile],

    props: {
      UG: Object,
      accessRoles: Array,
    },

    data() {
      return {
        options: [{}],
      }
    },

    mounted() {

    },

    methods: {

    }
  }
</script>

<style lang="scss">
  .sub-user .card-detail-left__id{
    @media (min-width: 1200px) {
      font-size: 32px;
    }
  }
</style>

